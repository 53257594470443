<template>
  <div class="create-survey-form">
    <v-row v-if="dataLoaded">
      <v-col>
        <h2
          class="tw-text-left tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green"
        >
          Create Survey
        </h2>
        <p
          class="info-blurb tw-text-left tw-text-sm tw-font-figtree tw-font-normal tw-leading-22 tw-text-light-grey tw-py-4"
        >
          Build a trip survey by selecting from our most popular questions
          below. This survey will be sent with your trip invitation to help
          kickstart planning.
        </p>
        <h3
          class="tw-text-left tw-text-dark-green tw-text-base tw-font-semibold"
        >
          Popular questions
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn-toggle
          class="j-btn-toggle tw-flex-col tw-bg-transparent tw-w-full"
          v-model="surveyQuestionsSelected"
          borderless
          rounded
          multiple
        >
          <v-btn
            v-for="question in surveyQuestionsSuggested"
            :key="question.id"
            :value="question.text"
            rounded
            block
            x-large
            class="tw-rounded-full tw-bg-lighter-grey tw-text-dark-green tw-mb-3 tw-pl-8 tw-shadow-md tw-tracking-normal"
          >
            <v-icon v-if="!questionInArray(question)" left dark
              >mdi-plus</v-icon
            >
            <v-icon v-if="questionInArray(question)" left dark
              >mdi-check</v-icon
            >
            <v-spacer />
            {{ question.text }}
            <v-spacer />
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3
          class="tw-text-left tw-text-dark-green tw-text-base tw-font-semibold"
        >
          Other questions
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn-toggle
          class="j-btn-toggle tw-flex-col tw-bg-transparent tw-w-full"
          v-model="surveyQuestionsSelected"
          borderless
          rounded
          multiple
        >
          <v-btn
            v-for="question in surveyQuestionsOther"
            :key="question.id"
            :value="question.text"
            rounded
            block
            x-large
            class="tw-rounded-full tw-bg-lighter-grey tw-text-dark-green tw-mb-3 tw-pl-8 tw-shadow-md tw-font-semibold tw-tracking-normal"
          >
            <v-icon
              v-if="surveyQuestionsSelected.indexOf(question.text) === -1"
              left
              dark
              >mdi-plus</v-icon
            >
            <v-icon
              v-if="surveyQuestionsSelected.indexOf(question.text) > -1"
              left
              dark
              >mdi-check</v-icon
            >
            <v-spacer />
            {{ question.text }}
            <v-spacer />
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <p
        class="tw-text-center tw-w-full tw-my-6 tw-font-figtree tw-text-dark-green tw-text-sm"
      >
        Custom question? Add it on the next page.
      </p>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-btn
          @click.native="nextStep"
          block
          rounded
          x-large
          color="primary"
          class="tw-tracking-normal"
        >
          Next
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <router-link
          v-if="trip"
          class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block"
          :to="{ name: 'TripViewRoute', params: { id: trip.id } }"
        >
          Skip and Create Later
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SUGGESTED_QUESTIONS, OTHER_QUESTIONS } from "@/enums/questions.js";
export default {
  name: "CreateSurveyForm",
  props: ["selectedQuestion"],
  data() {
    return {
      dataLoaded: false,
      surveyQuestionsSelected: [],
      surveyQuestionsSuggested: SUGGESTED_QUESTIONS,
      surveyQuestionsOther: OTHER_QUESTIONS
    };
  },

  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },

  methods: {
    nextStep() {
      this.$store.commit(
        "meta/addSurveyQuestions",
        this.surveyQuestionsSelected
      );
      //save questions in case of refresh or edit route
      localStorage.setItem(
        "surveyQuestions",
        JSON.stringify(this.surveyQuestionsSelected)
      );

      this.$router.push({ name: "SurveyConfirmRoute" });
    },
    questionInArray(question) {
      return this.surveyQuestionsSelected.some((q) => q === question.text);
    }
  },

  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Trip Survey",
      bgColor: "#203848",
      fontColor: "#E6FFA1",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    this.dataLoaded = true;
  },
  mounted() {
    if (this.selectedQuestion) {
      this.surveyQuestionsSelected.push(
        this.surveyQuestionsSuggested.filter(
          (question) => question.id == this.selectedQuestion
        )[0]
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
  flex-direction: column;
}

.v-btn-toggle.j-btn-toggle {
  background-color: transparent;
}

.j-btn-toggle button.v-btn {
  .v-icon:before {
    color: #203848;
    font-size: 1.8rem;
    font-weight: bold;
  }
}

button.v-btn.v-btn--active {
  background-color: #203848 !important;
  color: #ffffff !important;

  .v-icon:before {
    color: #ffffff;
  }
}
</style>
